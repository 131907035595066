<template>
  <AuthenticationForm id="view-no-realm">
    <div class="py-4 px-4 sm:px-8">
      <h3 class="text-base font-semibold mb-6 text-center">
        Set Company
      </h3>
      <ToznyForm :error="error" :onSubmit="btnSet" class="realm-form">
        <ToznyInput
          v-model="realm"
          id="realm"
          name="realm"
          class="mb-6 md:text-lil"
          placeholder="mycompany"
          label="Realm Name"
          float-label
        />
        <ToznyButton
          id="realm-button"
          class="login-button w-full py-3 rounded-lg text-lil mb-4"
          buttonText="Set"
        />
      </ToznyForm>
    </div>
  </AuthenticationForm>
</template>

<script>
import AuthenticationForm from '../../Common/AuthenticationFrom/AuthenticationForm'
import ToznyForm from '../../Common/ToznyForm'
import ToznyButton from '../../Common/ToznyButton'
import ToznyInput from '../../Common/ToznyInput'
export default {
  name: 'no-realm',
  components: {
    AuthenticationForm,
    ToznyForm,
    ToznyButton,
    ToznyInput,
  },
  data: function() {
    return {
      error: '',
      realm: '',
    }
  },
  methods: {
    async btnSet() {
      const realmName = this.realm.trim()
      if (realmName === '') {
        this.error = 'Realm name can not be empty'
        return
      }
      if (!/^[a-zA-Z0-9]{1,30}$/.test(realmName)) {
        this.error = 'Realm name must be 1-30 alpha-numeric characters'
        return
      }
      this.$router.push(`/${realmName}`)
    },
  },
}
</script>
